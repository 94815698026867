const getRequestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
};

export function ToTitleCase(str) {
  if (!str) {
    return "";
  }

  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}

export function formatCityName(str) {
  if (!str) {
    return "";
  }
  // Split the input string by ", " into an array
  const parts = str.split(", ");

  // Check if the input is in the expected format
  if (parts.length === 2) {
    // Rearrange the parts and join them back together
    const formattedName = parts[1] + " " + parts[0];
    return formattedName;
  } else {
    return str;
  }
}

export function getName(str) {
  if (!str) {
    return "";
  }

  const parts = str.split(", ");
  return parts[0];
}

export function filterImages(str, type) {
    if (str.includes("https://lookaside.fbsbx.com/")) {
        if (type == "provider") {
            return '/sample-water-provider-picture.png';
        } else if (type == "neighborhoods") {
            return '/sample-neighborhood-picture.webp';
        } else if (type == "restaurants") {
            return '/sample-restaurant-pic.jpeg';
        } else {
            throw Error;
        }
    }
    return str;
}

// get related data based on IDs
export const getRelated = (relatedData, relatedIds) => {
  let idx = 0;
  const data = [];
  for (const [key, value] of Object.entries(relatedData)) {
    if (value.metadata.id == relatedIds[idx]) {
      data.push(value);
      idx += 1;
    }
  }
  return data;
};

// Fetches and returns map of commits per person
async function fetchAllCommits(url) {
  const params = {
    per_page: 100,
    all: true,
    scope: "all",
  };

  // Create a query string from the parameters
  const queryString = new URLSearchParams(params).toString();
  const urlWithParams = `${url}?${queryString}`;

  let allCommits = [];
  let nextPage = 1;
  do {
    const response = await fetch(urlWithParams, getRequestOptions);
    const data = await response.json();
    allCommits = allCommits.concat(data);
    nextPage = parseInt(response.headers.get("X-Next-Page"));
  } while (nextPage);
  return allCommits;
}

export const fetchCommitData = async (url, members) => {
  try {
    const response = await fetch(url, getRequestOptions);
    const data = await response.json();

    const emailMap = new Map();
    for (const contributor of Object.values(data)) {
      emailMap.set(contributor.email, contributor.commits);
    }

    // match each member with their emails and sum up commits per person
    const res = new Map();
    for (const member of Object.values(members)) {
      let totalCommits = 0;
      for (const email of Object.values(member.emails)) {
        if (emailMap.has(email)) {
          totalCommits += emailMap.get(email);
        }
      }
      res.set(member.name, totalCommits);
    }

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Fetches and returns map of issues per person
export const fetchIssueData = async (url) => {
  try {
    const params = {
      per_page: 100,
      all: true,
      scope: "all",
    };

    // Create a query string from the parameters
    const queryString = new URLSearchParams(params).toString();
    const urlWithParams = `${url}?${queryString}`;
    const response = await fetch(urlWithParams, getRequestOptions);
    const data = await response.json();

    const res = new Map();
    for (const issue of Object.values(data)) {
      let name = issue.author.name;
      if (!res.has(name)) {
        res.set(name, 0);
      }
      res.set(name, res.get(name) + 1);
    }

    return res;
  } catch (error) {
    console.log(error);
  }
};

// Fetch data from API, store result in state variable
// https://codedamn.com/news/reactjs/how-to-use-fetch-in-react

export const fetchInstances = async (setInstances, url, requestOptions) => {
  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    setInstances(data);
  } catch (error) {
    console.log(error);
  }
};

export const buildQueryParams = (sort, options) => {
  let newQueryParams = "?" + Array.from(options).join("&");
  if (newQueryParams.length > 1 && sort.length > 0) {
    newQueryParams += "&";
  }
  newQueryParams += sort;
  return newQueryParams;
};
