import NavBar from "../../components/Navbar";
import Dropdown from "../../components/Dropdown";
import Grid from "../../components/Grid";
import { useState, useEffect } from "react";
import NeighborhoodInstanceCard from "../../components/Neighborhoods/NeighborhoodInstanceCard";
import PaginationController from "../../components/PaginationController";
import SearchBar from "../../components/SearchBar";
import SearchResultsList from "../../components/SearchResultList";
import { fetchFilters, fetchNeighborhoods } from "../../utils/api";
import { buildQueryParams } from "../../utils/utils";

function Neighborhoods() {
  const perPage = 25;

  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(new Set());
  const [selectedSort, setSelectedSort] = useState("");

  // get data for all neighborhoods and make iterable
  useEffect(() => {
    setLoading(true);
    const fetchAllNeigborhoods = async () => {
      const queryParams = buildQueryParams(selectedSort, selectedOptions);
      const data = await fetchNeighborhoods(currentPage, perPage, queryParams);

      setTotalCount(data.total_count);
      setTotalPages(Math.ceil(data.total_count / perPage));
      setNeighborhoods(data.response);
    };

    fetchAllNeigborhoods();
    setLoading(false);
  }, [currentPage, selectedOptions, selectedSort]);

  // TODO: move to json or backend later
  const orderDropdownElements = [
    {
      name: "Population (Ascending)",
      subOptions: [],
      queryName: "population",
      type: "sort",
      order: "asc",
    },
    {
      name: "Population (Descending)",
      subOptions: [],
      queryName: "population",
      type: "sort",
      order: "desc",
    },
    {
      name: "Average Household Income (Ascending)",
      subOptions: [],
      queryName: "avg_Household_Income",
      type: "sort",
      order: "asc",
    },
    {
      name: "Average Household Income (Descending)",
      subOptions: [],
      queryName: "avg_Household_Income",
      type: "sort",
      order: "desc",
    },
    {
      name: "Median Rent (Ascending)",
      subOptions: [],
      queryName: "housing_Median_Rent",
      type: "sort",
      order: "asc",
    },
    {
      name: "Median Rent (Descending)",
      subOptions: [],
      queryName: "housing_Median_Rent",
      type: "sort",
      order: "desc",
    },
    {
      name: "Total Housing Units (Ascending)",
      subOptions: [],
      queryName: "housing_Units",
      type: "sort",
      order: "asc",
    },
    {
      name: "Total Housing Units (Descending)",
      subOptions: [],
      queryName: "housing_Units",
      type: "sort",
      order: "desc",
    },
    {
      name: "Available Housing Units (Ascending)",
      subOptions: [],
      queryName: "housing_Units_Vacant",
      type: "sort",
      order: "asc",
    },
    {
      name: "Available Housing Units (Descending)",
      subOptions: [],
      queryName: "housing_Units_Vacant",
      type: "sort",
      order: "desc",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const fetchNeighborhoodFilters = async () => {
      const res = await fetchFilters("neighborhoods");
      setFilters(res);
    };
    fetchNeighborhoodFilters();
    setLoading(false);
  }, []);

  const [results, setResults] = useState([]);

  if (loading) return <h1>Loading...</h1>;
  return (
    <>
      <NavBar />
      <div className="model-search-area">
        <h1>Neighborhoods</h1>
        <div className="input-group rounded" id="model-search-bar">
          <SearchBar setResults={setResults} filter="neighborhoods" />
          <SearchResultsList results={results} type="neighborhoods" />
        </div>

        {filters.length > 0 && (
          <div className="model-dropdown-container">
            <Dropdown
              title="Sort By"
              dropdownElements={orderDropdownElements}
              setSelectedSort={setSelectedSort}
            />
            <Dropdown
              title="Filter By"
              dropdownElements={filters}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          </div>
        )}
        <br></br>
        <br></br>

        <PaginationController
          totalPages={totalPages}
          setPage={setCurrentPage}
        />
        <h4>
          Found <b>{totalCount}</b> neighborhoods
        </h4>
      </div>
      <Grid
        gridElements={neighborhoods.map((neighborhood, i) => (
          <NeighborhoodInstanceCard key={i} instanceData={neighborhood} />
        ))}
        numCols={Math.min(neighborhoods.length, 5)}
      />
    </>
  );
}

export default Neighborhoods;
