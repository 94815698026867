import SearchResult from "./SearchResult";
import { useEffect } from "react";

function SearchResultsList(props) {
  return (
    <div className="results-list">
      {props.results.map((result, id) => {
        return <SearchResult result={result} key={id} type={props.type} />;
      })}
    </div>
  );
}

export default SearchResultsList;
