import { useState } from "react";

function Dropdown(props) {
  const dropdownElements = props.dropdownElements;
  const setSelectedSort = props.setSelectedSort;
  const selectedOptions = props.selectedOptions;
  const setSelectedOptions = props.setSelectedOptions;

  const [sortName, setSortName] = useState("");

  function generateParam(filter, option) {
    switch (filter.type) {
      case "range":
        // Parse range from options
        let range = option.replace(/[^a-zA-Z0-9-]/g, "").split("-");
        // Append upper bound if none was specified
        if (range.length == 1) range.push(Number.MAX_SAFE_INTEGER);
        return `${filter.queryName}=${range[0]},${range[1]}`;
      case "match":
        // remove null values
        let opt = option.replace("None, ", "");
        // remove +1 for phone numbers
        opt = opt.replace("+1 ", "");
        return `${filter.queryName}=${opt}`;
      case "sort":
        return `sort_attribute=${filter.queryName}&sort=${filter.order}`;
      default:
        console.log(`Invalid filter type ${filter.type}`);
    }
  }

  function handleOptionClick(filter) {
    if (!setSelectedSort) {
      return;
    }

    const newSort = generateParam(filter);
    setSelectedSort(newSort);
    setSortName(filter.name);
  }

  function handleSubOptionClick(filter, option) {
    if (!setSelectedOptions) {
      return;
    }
    let newParam = generateParam(filter, option);
    const updatedOptions = new Set(selectedOptions);
    // if selected already, deselect
    if (selectedOptions.has(newParam)) {
      updatedOptions.delete(newParam);
    } else {
      updatedOptions.add(newParam);
    }
    setSelectedOptions(updatedOptions);
  }

  return (
    <div className="dropdown" id="dropdown">
      <button
        className="btn btn-secondary btn-lg dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-bs-auto-close={
          dropdownElements[0].subOptions.length > 0 ? "outside" : "inside"
        }
      >
        {props.title + (sortName ? ": " + sortName : "")}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {dropdownElements.map((dropdownElement, i) => (
          <li
            className={dropdownElement.subOptions.length > 0 ? "dropend" : ""}
          >
            <a
              className={`dropdown-item ${
                dropdownElement.subOptions.length > 0 ? "dropdown-toggle" : ""
              }`}
              data-bs-toggle="dropdown"
              key={i}
              href="#"
              onClick={() => handleOptionClick(dropdownElement)}
            >
              {dropdownElement.name}
            </a>
            <ul className="dropdown-menu">
              {dropdownElement.subOptions &&
                dropdownElement.subOptions.map((option, j) => (
                  <li>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id={`checkbox_${i}_${j}`}
                      onChange={() =>
                        handleSubOptionClick(dropdownElement, option)
                      }
                    />
                    <label class="form-check-label" for={`checkbox_${i}_${j}`}>
                      {option}
                    </label>
                  </li>
                ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Dropdown;
