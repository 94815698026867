function ToolCard(props) {
  return (
    <div className="card" id="tool-card">
      <img className="card-img-top" src={props.image} alt="Picture here" />
      <div className="card-body" id="tool-card-text">
        <p>{props.description}</p>
        <h2>{props.name}</h2>
      </div>
    </div>
  );
}

export default ToolCard;
