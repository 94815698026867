import { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import Dropdown from "../../components/Dropdown";
import ProviderInstanceCard from "../../components/WaterProviders/ProviderInstanceCard";
import Grid from "../../components/Grid";
import PaginationController from "../../components/PaginationController";
import SearchBar from "../../components/SearchBar";
import SearchResultsList from "../../components/SearchResultList";
import { fetchFilters, fetchWaterProviders } from "../../utils/api";
import { buildQueryParams } from "../../utils/utils";

function WaterProviders() {
  // we can just set this manually
  const perPage = 25;

  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [waterProviders, setWaterProviders] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(new Set());
  const [selectedSort, setSelectedSort] = useState("");

  // get data for all water providers and make iterable
  useEffect(() => {
    setLoading(true);
    const fetchProviders = async () => {
      const queryParams = buildQueryParams(selectedSort, selectedOptions);
      const data = await fetchWaterProviders(currentPage, perPage, queryParams);

      setTotalCount(data.total_count);
      setTotalPages(Math.ceil(data.total_count / perPage));
      setWaterProviders(data.response);
      console.log(data.response);
    };

    fetchProviders();
    setLoading(false);
  }, [currentPage, selectedOptions, selectedSort]);

  // TODO: move to json eventually
  const orderDropdownElements = [
    {
      name: "Population Served (Ascending)",
      subOptions: [],
      queryName: "population_served_count",
      type: "sort",
      order: "asc",
    },
    {
      name: "Population Served (Descending)",
      subOptions: [],
      queryName: "population_served_count",
      type: "sort",
      order: "desc",
    },
    {
      name: "Address (Ascending)",
      subOptions: [],
      queryName: "address_line1",
      type: "sort",
      order: "asc",
    },
    {
      name: "Address (Descending)",
      subOptions: [],
      queryName: "address_line1",
      type: "sort",
      order: "desc",
    },
    {
      name: "Zip Code (Ascending)",
      subOptions: [],
      queryName: "zip_code",
      type: "sort",
      order: "asc",
    },
    {
      name: "Zip Code (Descending)",
      subOptions: [],
      queryName: "zip_code",
      type: "sort",
      order: "desc",
    },
    {
      name: "Phone Number (Ascending)",
      subOptions: [],
      queryName: "phone_number",
      type: "sort",
      order: "asc",
    },
    {
      name: "Phone Number (Descending)",
      subOptions: [],
      queryName: "phone_number",
      type: "sort",
      order: "desc",
    },
    {
      name: "Email (Ascending)",
      subOptions: [],
      queryName: "email_addr",
      type: "sort",
      order: "asc",
    },
    {
      name: "Email (Descending)",
      subOptions: [],
      queryName: "email_addr",
      type: "sort",
      order: "desc",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const fetchNeighborhoodFilters = async () => {
      const res = await fetchFilters("water-providers");
      setFilters(res);
    };
    fetchNeighborhoodFilters();
    setLoading(false);
  }, []);

  const [results, setResults] = useState([]);

  if (loading) return <h1>Loading...</h1>;
  return (
    <>
      <NavBar />
      <div className="model-search-area">
        <h1>Water Providers</h1>
        <div className="input-group rounded" id="model-search-bar">
          <SearchBar setResults={setResults} filter="water-providers" />
          <SearchResultsList results={results} type="water-providers" />
        </div>

        {filters.length > 0 && (
          <div className="model-dropdown-container">
            <Dropdown
              title="Sort By"
              dropdownElements={orderDropdownElements}
              setSelectedSort={setSelectedSort}
            />
            <Dropdown
              title="Filter By"
              dropdownElements={filters}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          </div>
        )}
        <br></br>
        <br></br>

        <PaginationController
          totalPages={totalPages}
          setPage={setCurrentPage}
        />
        <h4>
          Found <b>{totalCount}</b> water providers
        </h4>
      </div>
      <Grid
        gridElements={waterProviders.map((waterProvider, i) => (
          <ProviderInstanceCard key={i} instanceData={waterProvider} />
        ))}
        numCols={Math.min(waterProviders.length, 5)}
      />
    </>
  );
}

export default WaterProviders;
