import NavBar from "../../components/Navbar";
import Grid from "../../components/Grid";
import { useParams } from "react-router-dom";
import { getName, filterImages } from "../../utils/utils";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useState, useEffect } from "react";
import {
  fetchRelatedInstances,
  fetchNeighborhood,
  GOOGLE_API_KEY,
  fetchGoogleImage,
} from "../../utils/api";
import ProviderInstanceCard from "../../components/WaterProviders/ProviderInstanceCard";
import RestaurantInstanceCard from "../../components/Restaurants/RestaurantInstanceCard";

function NeighborhoodInstance(props) {
  const defaultCenter = { lat: 43.012361053526135, lng: -83.68679531519122 };

  const { id } = useParams();
  const [instance, setInstance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedWaterProviders, setRelatedWaterProviders] = useState(null);
  const [relatedRestaurants, setRelatedRestaurants] = useState(null);

  // get neighborhood data
  useEffect(() => {
    setLoading(true);
    const fetchSingleNeigborhood = async () => {
      const data = await fetchNeighborhood(id);
      setInstance(data);
    };

    fetchSingleNeigborhood();
    setLoading(false);
  }, []);

  // get related cards data
  useEffect(() => {
    if (instance == null) {
      return;
    }
    setLoading(true);
    const fetchRelatedProviders = async () => {
      if (instance.related_provider_ids.length != 0) {
        const ids = instance.related_provider_ids.split(",");
        setRelatedWaterProviders(
          await fetchRelatedInstances(ids, "water-providers"),
        );
      }
    };

    const fetchRelatedRestaurants = async () => {
      if (instance.related_restaurant_ids.length != 0) {
        const ids = instance.related_restaurant_ids.split(",");
        setRelatedRestaurants(await fetchRelatedInstances(ids, "restaurants"));
      }
    };

    fetchRelatedProviders();
    fetchRelatedRestaurants();
    setLoading(false);
  }, [instance]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  if (loading) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <>
        <NavBar />
        <div className="instance-container" id="neighborhood">
          <img
            src={instance ? filterImages(instance.image_link, "neighborhoods") : ""}
            alt="Instance image"
            className="instance-image"
          />
          <h1>{getName(instance?.name) || "Loading.."}</h1>
          <p>Population: {instance?.population || "Loading.."}</p>
          <p>
            Average Household Income: $
            {instance?.avg_Household_Income || "Loading.."}
          </p>
          <p>Median Rent: ${instance?.housing_Median_Rent || "Loading.."}</p>
          <p>Total Housing Units: {instance?.housing_Units || "Loading.."}</p>

          <div className="map-div">
            {!isLoaded ? (
              <h2>Loading...</h2>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container"
                center={
                  instance
                    ? { lat: instance.latitude, lng: instance.longitude }
                    : defaultCenter
                }
                zoom={14}
              >
                <MarkerF
                  position={
                    instance
                      ? { lat: instance.latitude, lng: instance.longitude }
                      : defaultCenter
                  }
                />
              </GoogleMap>
            )}
          </div>
          <br></br>
          <br></br>
        </div>
        <br></br>
        <br></br>
        <h1 style={{ textAlign: "center" }}>Related Water Providers</h1>
        {relatedWaterProviders && (
          <Grid
            gridElements={relatedWaterProviders.map((provider, i) => (
              <ProviderInstanceCard key={i} instanceData={provider} />
            ))}
            numCols={Math.min(relatedWaterProviders.length, 5)}
          />
        )}
        <br></br>
        <br></br>
        <h1 style={{ textAlign: "center" }}>Related Restaurants</h1>
        {relatedRestaurants && (
          <Grid
            gridElements={relatedRestaurants.map((restaurant, i) => (
              <RestaurantInstanceCard key={i} instanceData={restaurant} />
            ))}
            numCols={Math.min(relatedRestaurants.length, 5)}
          />
        )}
      </>
    );
  }
}

export default NeighborhoodInstance;
