import NavBar from "../../components/Navbar";
import Dropdown from "../../components/Dropdown";
import Grid from "../../components/Grid";
import { useState, useEffect } from "react";
import RestaurantInstanceCard from "../../components/Restaurants/RestaurantInstanceCard";
import PaginationController from "../../components/PaginationController";
import SearchBar from "../../components/SearchBar";
import SearchResultsList from "../../components/SearchResultList";
import { fetchFilters, fetchRestaurants } from "../../utils/api";
import { buildQueryParams } from "../../utils/utils";

function Restaurants() {
  const perPage = 25;

  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(new Set());
  const [selectedSort, setSelectedSort] = useState("");

  // get data for all water providers and make iterable
  useEffect(() => {
    setLoading(true);
    const fetchProviders = async () => {
      const queryParams = buildQueryParams(selectedSort, selectedOptions);
      console.log(queryParams);
      const data = await fetchRestaurants(currentPage, perPage, queryParams);
      setTotalCount(data.total_count);
      setTotalPages(Math.ceil(data.total_count / perPage));
      setRestaurants(data.response);
    };

    fetchProviders();
    setLoading(false);
  }, [currentPage, selectedOptions, selectedSort]);

  // TODO: move to json eventually
  const orderDropdownElements = [
    {
      name: "Address (Ascending)",
      subOptions: [],
      queryName: "address",
      type: "sort",
      order: "asc",
    },
    {
      name: "Address (Descending)",
      subOptions: [],
      queryName: "address",
      type: "sort",
      order: "desc",
    },
    {
      name: "Cuisine (Ascending)",
      subOptions: [],
      queryName: "cuisine",
      type: "sort",
      order: "asc",
    },
    {
      name: "Cuisine (Descending)",
      subOptions: [],
      queryName: "cuisine",
      type: "sort",
      order: "desc",
    },
    {
      name: "Dietary Restrictions (Ascending)",
      subOptions: [],
      queryName: "dietary_restrictions",
      type: "sort",
      order: "asc",
    },
    {
      name: "Dietary Restrictions (Descending)",
      subOptions: [],
      queryName: "dietary_restrictions",
      type: "sort",
      order: "desc",
    },
    {
      name: "Rating (Ascending)",
      subOptions: [],
      queryName: "rating",
      type: "sort",
      order: "asc",
    },
    {
      name: "Rating (Descending)",
      subOptions: [],
      queryName: "rating",
      type: "sort",
      order: "desc",
    },
    {
      name: "Phone Number (Ascending)",
      subOptions: [],
      queryName: "phone",
      type: "sort",
      order: "asc",
    },
    {
      name: "Phone Number (Descending)",
      subOptions: [],
      queryName: "phone",
      type: "sort",
      order: "desc",
    },
  ];

  useEffect(() => {
    setLoading(true);
    const fetchNeighborhoodFilters = async () => {
      const res = await fetchFilters("restaurants");
      setFilters(res);
    };
    fetchNeighborhoodFilters();
    setLoading(false);
  }, []);

  const [results, setResults] = useState([]);

  if (loading) return <h1>Loading...</h1>;
  return (
    <>
      <NavBar />
      <div className="model-search-area">
        <h1>Restaurants</h1>

        <div className="input-group rounded" id="model-search-bar">
          <SearchBar setResults={setResults} filter="restaurants" />
          <SearchResultsList results={results} type="restaurants" />
        </div>

        {filters.length > 0 && (
          <div className="model-dropdown-container">
            <Dropdown
              title="Sort By"
              dropdownElements={orderDropdownElements}
              setSelectedSort={setSelectedSort}
            />
            <Dropdown
              title="Filter By"
              dropdownElements={filters}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          </div>
        )}
        <br></br>
        <br></br>

        <PaginationController
          totalPages={totalPages}
          setPage={setCurrentPage}
        />
        <h4>
          Found <b>{totalCount}</b> restaurants
        </h4>
      </div>
      <Grid
        gridElements={restaurants.map((restaurant, i) => (
          <RestaurantInstanceCard
            key={i}
            type="restaurants"
            instanceData={restaurant}
          />
        ))}
        numCols={Math.min(restaurants.length, 5)}
      />
    </>
  );
}

export default Restaurants;
