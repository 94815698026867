import NavBar from "../../components/Navbar";
import Grid from "../../components/Grid";
import { useParams } from "react-router-dom";
import { ToTitleCase, filterImages } from "../../utils/utils";
import { useState, useEffect } from "react";
import {
  fetchRelatedInstances,
  fetchRestaurant,
  GOOGLE_API_KEY,
} from "../../utils/api";
import NeighborhoodInstanceCard from "../../components/Neighborhoods/NeighborhoodInstanceCard";
import ProviderInstanceCard from "../../components/WaterProviders/ProviderInstanceCard";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

function RestaurantInstance() {
  const defaultCenter = { lat: 43.012361053526135, lng: -83.68679531519122 };

  let { id } = useParams();
  const [instance, setInstance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [relatedWaterProviders, setRelatedWaterProviders] = useState(null);
  const [relatedNeighborhoods, setRelatedNeighborhoods] = useState(null);

  // get contaminant data
  useEffect(() => {
    setLoading(true);
    const fetchSingleContaminant = async () => {
      let data = await fetchRestaurant(id);
      // replace null values
      for (const [key, value] of Object.entries(data)) {
        if (typeof value == "string" && !value) {
          data[key] = "None";
        }
      }
      setInstance(data);
    };

    fetchSingleContaminant();
    setLoading(false);
  }, []);

  // get related cards data
  useEffect(() => {
    if (instance == null) {
      return;
    }
    setLoading(true);

    const fetchRelatedWaterProviders = async () => {
      if (instance.related_provider_ids.length != 0) {
        const ids = instance.related_provider_ids.split(",");
        setRelatedWaterProviders(
          await fetchRelatedInstances(ids, "water-providers"),
        );
      }
    };
    const fetchRelatedNeighborhoods = async () => {
      if (instance.related_neighborhood_ids.length != 0) {
        const ids = instance.related_neighborhood_ids.split(",");
        setRelatedNeighborhoods(
          await fetchRelatedInstances(ids, "neighborhoods"),
        );
      }
    };

    fetchRelatedWaterProviders();
    fetchRelatedNeighborhoods();
    setLoading(false);
  }, [instance]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  if (loading) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <>
        <NavBar />
        <div className="instance-container" id="restaurant">
          <img
            src={instance ? filterImages(instance.image_link, "restaurants") : ""}
            alt="Instance image"
            className="instance-image"
          />
          <h1>{ToTitleCase(instance?.name) || "Loading..."}</h1>
          <p>Address: {instance?.address || "Loading..."}</p>
          <p>Cuisine: {instance?.cuisine || "Loading..."}</p>
          <p>
            Dietary Restrictions:{" "}
            {instance?.dietary_restrictions || "Loading..."}
          </p>
          <p>Rating: {instance?.rating || "Loading..."}</p>
          <p>Phone: {instance?.phone || "Loading..."}</p>

          <div className="map-div">
            {!isLoaded ? (
              <h2>Loading...</h2>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container"
                center={
                  instance
                    ? {
                        lat: parseFloat(instance.latitude),
                        lng: parseFloat(instance.longitude),
                      }
                    : defaultCenter
                }
                zoom={14}
              >
                <MarkerF
                  position={
                    instance
                      ? {
                          lat: parseFloat(instance.latitude),
                          lng: parseFloat(instance.longitude),
                        }
                      : defaultCenter
                  }
                />
              </GoogleMap>
            )}
          </div>
        </div>
        <br></br>
        <br></br>
        <h1 style={{ textAlign: "center" }}>Related Water Providers</h1>
        {relatedWaterProviders && (
          <Grid
            gridElements={relatedWaterProviders.map((waterProvider, i) => (
              <ProviderInstanceCard key={i} instanceData={waterProvider} />
            ))}
            numCols={Math.min(relatedWaterProviders.length, 5)}
          />
        )}
        <br></br>
        <br></br>
        <h1 style={{ textAlign: "center" }}>Related Neighborhoods</h1>
        {relatedNeighborhoods && (
          <Grid
            gridElements={relatedNeighborhoods.map((neighborhood, i) => (
              <NeighborhoodInstanceCard key={i} instanceData={neighborhood} />
            ))}
            numCols={Math.min(relatedNeighborhoods.length, 5)}
          />
        )}
      </>
    );
  }
}

export default RestaurantInstance;
