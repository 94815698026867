import { useState, useEffect } from "react";
import {
  BarChart,
  Tooltip,
  Legend,
  Label,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import { fetchProviderVisData } from "../../utils/api";

function CollegeTuitionChart() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchProviderVisData();
      setData(response.collegeTuition);
    };
    fetchData();
  }, []);

  return (
    <>
      {data && (
        <BarChart width={730} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="tuition">
            <Label
              value="Annual Tuition ($)"
              offset={-5}
              position="insideBottom"
            />
          </XAxis>
          <YAxis>
            <Label value="Count" offset={-15} angle={-90} position="left" />
          </YAxis>
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      )}
    </>
  );
}

export default CollegeTuitionChart;
