import { useState, useEffect } from "react";

function PaginationButton(props) {
  const activePage = props.activePage;
  const startNum = props.startNum;
  const index = props.index;
  const handlePageClick = props.handlePageClick;

  return (
    <li className="page-item" key={index}>
      <button
        className="page-link"
        id={`${
          activePage == index + startNum ? "page-button-active" : "page-button"
        }`}
        onClick={() => handlePageClick(index + startNum)}
      >
        {index + startNum}
      </button>
    </li>
  );
}

function PaginationController(props) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const totalPages = props.totalPages;
  const setPage = props.setPage;

  const [startNum, setStartNum] = useState(1);
  const [activePage, setActivePage] = useState(1);

  // generate pagination buttons
  const paginationButtons = [];
  for (let i = 0; i < Math.min(5, totalPages); i++) {
    paginationButtons.push(
      <PaginationButton
        index={i}
        activePage={activePage}
        startNum={startNum}
        handlePageClick={handlePageClick}
      />,
    );
  }

  // calls setPage, and sets currently displaying page numbers
  function handlePageClick(pageNum) {
    // can't click page if it's already active
    if (activePage == pageNum) {
      return;
    }

    setActivePage(pageNum);

    // if totalPages is less than 5 don't change start num
    if (totalPages < 5) {
      return;
    }

    let newStartNum = pageNum - 2;
    if (newStartNum < 1) {
      newStartNum = 1;
    } else if (pageNum + 2 > totalPages) {
      newStartNum = totalPages - 4;
    }
    setStartNum(newStartNum);
  }

  function handlePrevClick() {
    if (startNum > 1) {
      setStartNum(startNum - 1);
      setActivePage(activePage - 1);
    } else if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  }

  function handleNextClick() {
    if (startNum < totalPages - 4) {
      setStartNum(startNum + 1);
      setActivePage(activePage + 1);
    } else if (activePage < totalPages) {
      setActivePage(activePage + 1);
    }
  }

  // update activePage variable on model page
  useEffect(() => {
    setPage(activePage);
  }, [activePage, setPage]);

  // handles responsiveness for different screen sizes
  useEffect(() => {
    // Update the screen size state when the window is resized
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <h4 id="total-pages-text">Total Pages: {totalPages}</h4>
      <nav>
        <ul
          className={`pagination pagination-${screenSize > 768 ? "lg" : "md"}`}
        >
          <li className="page-item" onClick={handlePrevClick}>
            <a
              className="page-link"
              id={`${activePage == 1 ? "page-button-disabled" : "page-button"}`}
            >
              Previous
            </a>
          </li>
          {paginationButtons}
          <li className="page-item" onClick={handleNextClick}>
            <a
              className="page-link"
              id={`${
                activePage == totalPages
                  ? "page-button-disabled"
                  : "page-button"
              }`}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default PaginationController;
