import { useState, useEffect } from "react";
import { fetchSearchResults } from "../utils/api";

function SearchBar(props) {
  const [input, setInput] = useState("");

  useEffect(() => {
    let ignore = false;
    if (input) {
      fetchSearchResults(input, props.filter).then((response) => {
        if (!ignore) {
          props.setResults(response);
        }
      });
    } else {
      props.setResults([]);
    }

    return () => {
      ignore = true;
    };
  }, [input]);

  return (
    <div className="input-wrapper">
      <input
        type="text"
        placeholder="Search"
        value={input}
        onChange={(e) => setInput(e.target.value)}
      ></input>
    </div>
  );
}

export default SearchBar;
