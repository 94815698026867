import { useNavigate } from "react-router-dom";
import { ToTitleCase, formatCityName, filterImages } from "../../utils/utils";
import { fetchGoogleImage } from "../../utils/api";
import { useEffect, useState } from "react";

function RestaurantInstanceCard(props) {
  const instanceData = props.instanceData;

  const properties = [];
  // Push props manually
  properties.push("Address: " + instanceData.address);
  properties.push("Cuisine: " + instanceData.cuisine);
  properties.push("Dietary Restrictions: " + instanceData.dietary_restrictions);
  properties.push("Rating: " + instanceData.rating);
  properties.push("Phone: " + instanceData.phone);

  const navigate = useNavigate();

  function handleClick() {
    const link = "/restaurants/" + instanceData.id;
    navigate(link);
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

  // prevent card text from overflowing
  function truncateText(string) {
    if (string.length > 30) {
      return string.slice(0, 30) + "...";
    }
    return string;
  }

  return (
    <div className="card" id="instance-card" onClick={handleClick}>
      <img
        className="card-img-top"
        id="instance-card-image"
        src={filterImages(instanceData.image_link, "restaurants")}
        alt="Card Image"
      />
      <div className="card-body">
        <h1>{formatCityName(ToTitleCase(instanceData.name))}</h1>
        {properties.map((prop, i) => (
          <p key={i}>{truncateText(prop)}</p>
        ))}
      </div>
      <div className="instance-card-button-container">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClick}
        >
          See more
        </button>
      </div>
    </div>
  );
}

export default RestaurantInstanceCard;
