import ProvidersByPopulationChart from '../components/Charts/ProvidersByPopulationChart';
import NeighborhoodsByRentChart from '../components/Charts/NeighborhoodsByRentChart';
import RestaurantsByRatingChart from '../components/Charts/RestaurantsByRatingChart';
import NavBar from '../components/Navbar';

function Visualizations() {
    return (
        <>
        <NavBar />
        <div id="visualizations-container">
            <h1>Visualizations</h1>
            <br></br>
            <br></br>
            <h2>Providers sorted by Population Served</h2>
            <ProvidersByPopulationChart />
            <br></br>
            <br></br>
            <hr id="provider-line-break" />
            <br></br>
            <br></br>
            <h2>Neighborhoods sorted by Rent</h2>
            <NeighborhoodsByRentChart />
            <hr id="provider-line-break" />
            <br></br>
            <br></br>
            <h2>Total number of each Restaurant Rating</h2>
            <RestaurantsByRatingChart />
            <br></br>
            <br></br>
        </div>
        </>
    );
}

export default Visualizations;