import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Landing from "./pages/Landing";
import About from "./pages/About";
import SearchPage from "./pages/SearchPage";
import WaterProviders from "./pages/WaterProviders/WaterProviders";
import Neighborhoods from "./pages/Neigborhoods/Neighborhoods";
import Restaurants from "./pages/Restaurants/Restaurants";
import WaterProviderInstance from "./pages/WaterProviders/WaterProviderInstance";
import NeighborhoodInstance from "./pages/Neigborhoods/NeighborhoodInstance";
import RestaurantInstance from "./pages/Restaurants/RestaurantInstance";
import Visualizations from "./pages/Visualizations";
import ProviderVisualizations from "./pages/ProviderVisualizations";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/water-providers" element={<WaterProviders />} />
        <Route path="/neighborhoods" element={<Neighborhoods />} />
        <Route path="/restaurants" element={<Restaurants />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/search?q=:query" element={<SearchPage />} />
        <Route path="/" element={<Landing />} />
        <Route
          path="/water-providers/:id"
          element={<WaterProviderInstance />}
        />
        <Route path="/neighborhoods/:id" element={<NeighborhoodInstance />} />
        <Route path="/restaurants/:id" element={<RestaurantInstance />} />
        <Route path="/visualizations" element={<Visualizations />} />
        <Route
          path="/provider-visualizations"
          element={<ProviderVisualizations />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
