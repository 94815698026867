import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify, API } from "aws-amplify";
import "./index.css";
import App from "./App";

Amplify.configure({
  // OPTIONAL - if your API requires authentication
  // Auth: {
  //   identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab', // REQUIRED - Amazon Cognito Identity Pool ID
  //   region: 'XX-XXXX-X', // REQUIRED - Amazon Cognito Region
  //   userPoolId: 'XX-XXXX-X_abcd1234', // OPTIONAL - Amazon Cognito User Pool ID
  //   userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  // },
  API: {
    endpoints: [
      {
        name: "FlintAquaAdvocatesAPI",
        endpoint: "https://api.flint-aqua-advocates.me",
      },
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
