import { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";
import { fetchVisData } from "../../utils/api";

const colors = [
    "#CF56E2", 
    "#68E256", 
    "#56E2CF", 
    "#E25668", 
    "#E2CF56",
];

function RestaurantsByRatingChart() {
    const [data, setData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetchVisData();
        setData(response.restaurantsByRating);
      };
      fetchData();
    }, []);
  
    return (
      <>
        {data && (
            <PieChart width={600} height={600}>
            <Pie
              data={data}
              cx={280}
              cy={250}
              labelLine={false}
              outerRadius={200}
              fill="#8884d8"
              dataKey="count"
            >
              {data.map((entry, idx) => (
                <Cell key={`cell-${idx}`} fill={colors[idx % colors.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        
        )}
      </>
    );
}

export default RestaurantsByRatingChart;