import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Plot from "react-plotly.js";
import { useState, useEffect } from "react";
import { fetchProviderVisData } from "../../utils/api";

const numScholarships = [];
const cityLat = [];
const cityLon = [];
const hoverText = [];

function CityScholarshipsChart() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchProviderVisData();
      setData(response.cityScholarships);
      response.cityScholarships.forEach((cityInfo) => {
        numScholarships.push(Math.sqrt(cityInfo.numScholarships) * 3);
        cityLat.push(cityInfo.lat);
        cityLon.push(cityInfo.lon);
        hoverText.push(
          `${cityInfo.name}; num scholarships: ${cityInfo.numScholarships}`,
        );
      });
    };
    fetchData();
  }, []);

  return (
    <div>
      {data && (
        <Container fluid="md">
          <Row>
            <Col>
              <Plot
                data={[
                  {
                    type: "scattergeo",
                    locationmode: "USA-states",
                    lat: cityLat,
                    lon: cityLon,
                    hoverinfo: "text",
                    text: hoverText,
                    marker: {
                      size: numScholarships,
                      line: {
                        color: "black",
                        width: 1,
                      },
                    },
                  },
                ]}
                layout={{
                  showlegend: false,
                  geo: {
                    scope: "usa",
                    showland: true,
                    landcolor: "rgb(217, 217, 217)",
                    subunitcolor: "rgb(255,255,255)",
                    center: { lon: -99, lat: 28 },
                    projection: {
                      type: "albers usa",
                    },
                  },
                }}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default CityScholarshipsChart;
