import { useNavigate } from "react-router-dom";
import { ToTitleCase, formatCityName, getName } from "../utils/utils";

function SearchResult(props) {
  const navigate = useNavigate();

  function handleClick() {
    const link = "/" + props.type + "/" + props.result.id;
    navigate(link); // Navigate to the "/treats" page
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

  if (props.type === "water-providers") {
    return (
      <div className="search-result" onClick={handleClick}>
        {formatCityName(ToTitleCase(props.result.name))}
      </div>
    );
  } else if (props.type === "neighborhoods") {
    return (
      <div className="search-result" onClick={handleClick}>
        {getName(props.result.name)}
      </div>
    );
  }
  return (
    <div className="search-result" onClick={handleClick}>
      {props.result.name}
    </div>
  );
}

export default SearchResult;
