import { useNavigate } from "react-router-dom";
import { ToTitleCase, formatCityName, filterImages } from "../../utils/utils";
import { fetchGoogleImage } from "../../utils/api";
import { useEffect, useState } from "react";

export default function ProviderInstanceCard(props) {
  const instanceData = props.instanceData;

  const properties = [];
  // Push props manually
  properties.push("Population Served: " + instanceData.population_served_count);
  properties.push(
    "Address: " + (instanceData.address ? instanceData.address : "See Map"),
  );
  properties.push(
    "Zip Code: " +
      (instanceData.zip_code ? instanceData.zip_code : "Unavailable"),
  );
  properties.push(
    "Phone Number: " +
      (instanceData.phone_number ? instanceData.phone_number : "Unavailable"),
  );
  properties.push(
    "Email: " + (instanceData.email ? instanceData.email : "Unavailable"),
  );

  const navigate = useNavigate();

  function handleClick() {
    const link = "/water-providers/" + instanceData.id;
    navigate(link);
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

  return (
    <div className="card" id="instance-card" onClick={handleClick}>
      <img
        className="card-img-top"
        id="instance-card-image"
        src={filterImages(instanceData.image_link, "provider")}
        alt="Card Image"
      />
      <div className="card-body">
        <h1>{formatCityName(ToTitleCase(instanceData.name))}</h1>
        {properties.map((prop, i) => (
          <p key={i}>{prop}</p>
        ))}
      </div>
      <div className="instance-card-button-container">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClick}
        >
          See more
        </button>
      </div>
    </div>
  );
}
