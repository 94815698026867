function MemberCard(props) {
  let member = props.member;

  return (
    <div className="card" id="member-card">
      <img
        className="card-img-top"
        src={member.image_name}
        width={275}
        height={275}
        alt="Picture here"
      />
      <div className="card-body">
        <h2>{member.name}</h2>
        <p>
          <b>Role:</b> {member.role}
        </p>
        <p>
          <b>Bio:</b> {member.bio}
        </p>
        <p>
          <b>Gitlab ID:</b> {member.gitlab_id}
        </p>
        <p>
          <b># Commits:</b> {props.numCommits != null ? props.numCommits : 0}
        </p>
        <p>
          <b># Issues:</b> {props.numIssues != null ? props.numIssues : 0}
        </p>
        <p>
          <b># Tests</b> {member.num_tests}
        </p>
      </div>
    </div>
  );
}

export default MemberCard;
