import NavBar from "../components/Navbar";
import MemberCard from "../components/MemberCard";
import ToolCard from "../components/ToolCard";
import Grid from "../components/Grid";
import memberData from "../data/member-data.json";
import toolsData from "../data/tools-data.json";
import { useState, useEffect } from "react";
import { fetchIssueData, fetchCommitData } from "../utils/utils";

function About() {
  let totalTests = 0;
  // fetch commit data for each member
  const [commits, setCommits] = useState(null);
  const [issues, setIssues] = useState(null);

  useEffect(() => {
    fetchCommitData(
      "https://gitlab.com/api/v4/projects/50467702/repository/contributors",
      memberData,
    )
      .then((data) => {
        setCommits(data);
      })
      .catch((error) => {
        console.error("Error fetching commit data:", error);
      });

    fetchIssueData("https://gitlab.com/api/v4/projects/50467702/issues")
      .then((data) => {
        setIssues(data);
      })
      .catch((error) => {
        console.error("Error fetching issue data:", error);
      });
  }, []);

  function getTotal(obj) {
    if (obj == null) {
      return 0;
    }
    let count = 0;
    for (const val of obj.values()) {
      count += val;
    }
    return count;
  }

  // make members mappable
  let members = [];
  for (const value of Object.values(memberData)) {
    // update total tests
    totalTests += parseInt(value.num_tests);
    members.push(value);
  }
  // make tools mappable
  let tools = [];
  for (const value of Object.values(toolsData)) {
    tools.push(value);
  }

  return (
    <>
      <NavBar />
      <div className="content-container">
        <div style={{ textAlign: "center" }}>
          <h1>About Page:</h1>
          <p
            style={{
              maxWidth: "1200px",
              width: "100%",
              wordWrap: "break-word",
              margin: "0 auto",
              fontSize: "20px",
            }}
          >
            Flint Aqua Advocates serves as a pivotal online platform, offering
            the most current and comprehensive information regarding the water
            situation in Flint, Michigan. The website is dedicated to
            enlightening the public about ongoing water quality issues,
            remediation efforts, and the overall progress being made to ensure
            access to safe and clean water for the residents of Flint.
          </p>
          <br></br>
          <br></br>
          <h4
            style={{
              maxWidth: "1200px",
              width: "100%",
              wordWrap: "break-word",
              margin: "0 auto",
            }}
          >
            Meet the team:
          </h4>
          <div className="member-card-container">
            {members.map((member, i) => (
              <MemberCard
                key={i}
                member={member}
                numCommits={commits != null ? commits.get(member.name) : 0}
                numIssues={issues != null ? issues.get(member.name) : 0}
              />
            ))}
          </div>
          <br></br>
          <br></br>
          <div className="total-stats-container">
            <h1>Total Stats</h1>
            <p>Total Commits: {getTotal(commits)}</p>
            <p>Total Issues: {getTotal(issues)}</p>
            {/* TODO: Fix once we add unit tests */}
            <p>Total Tests: {totalTests}</p>
          </div>
        </div>
        <br></br>
        <br></br>
        <h1 style={{ textAlign: "center" }}>Tools Used</h1>
        <Grid
          gridElements={tools.map((tool, i) => (
            <ToolCard
              key={i}
              image={tool.image_name}
              name={tool.name}
              description={tool.description}
            />
          ))}
          numCols={5}
        />
        <br></br>
        <br></br>
        <div className="interesting-conclusion-container">
          <div style={{ textAlign: "center" }}>
            <h1>Interesting Results</h1>
            <p>
              One of the most interesting things that we noted when connecting
              data about water providers, neighborhoods, and restaurants in
              Flint was that each instance was able to connect to many others;
              the water providers, neighborhoods, and restaurants throughout the
              city are so close to each other.
            </p>
          </div>
        </div>
        <div className="data-sources-container">
          <h1>Data sources</h1>
          <a href="https://www.epa.gov/enviro/sdwis-model" target="_blank">
            <h4>Environmental Protection Agency</h4>
          </a>
          <p>
            <b>Description:</b> Used for compiling various datapoints about
            water providers
          </p>
          <a
            href="https://www.attomdata.com/solutions/property-data-api/"
            target="_blank"
          >
            <h4>ATTOM</h4>
          </a>
          <p>
            <b>Description:</b> Used to get geographical data about
            neighborhoods in Flint
          </p>
          <a
            href="https://rapidapi.com/apidojo/api/travel-advisor"
            target="_blank"
          >
            <h4>Travel Advisor</h4>
          </a>
          <p>
            <b>Description:</b> Used to gather restaurant information
          </p>
          <a
            href="https://developers.google.com/maps/documentation/geocoding/overview"
            target="_blank"
          >
            <h4>Google Maps Platform</h4>
          </a>
          <p>
            <b>Description:</b> Used to estimate latitude and longitude of an
            address
          </p>
        </div>
        <br></br>
        <br></br>
        <div className="button-container">
          <a
            className="btn btn-secondary"
            id="about-page-button"
            href="https://gitlab.com/cs373-f23-group9/flint-aqua-advocates"
            target="_blank"
            role="button"
          >
            <h3>Gitlab Repo</h3>
          </a>
          <br></br>
          <br></br>
          <a
            className="btn btn-secondary"
            id="about-page-button"
            href="https://documenter.getpostman.com/view/29779387/2s9YJZ3jMM"
            target="_blank"
            role="button"
          >
            <h3>API Documentation</h3>
          </a>
        </div>
      </div>
    </>
  );
}

export default About;
