import axios from "axios";

const BASE_URL = 'https://api.flint-aqua-advocates.me'; // TODO: change to automatically switch between dev and prod URLs
// const BASE_URL = "http://localhost:5000";

export const GOOGLE_API_KEY = "AIzaSyD3VwYzeYjmL743Umonmnoy91vFrzeXeBQ";
const GOOGLE_SEARCH_KEY = "AIzaSyCfRsCepylJOiAxaizfDhXtDcKBh_zeMAM";

// Fetches page of water providers
export const fetchWaterProviders = async (page, perPage, queryParams) => {
  try {
    const response = await axios.get(`${BASE_URL}/providers${queryParams}`, {
      params: {
        page: page,
        per_page: perPage,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetches page of neighborhoods
export const fetchNeighborhoods = async (page, perPage, queryParams) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/neighborhoods${queryParams}`,
      {
        params: {
          page: page,
          per_page: perPage,
        },
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetches page of water providers
export const fetchRestaurants = async (page, perPage, queryParams) => {
  try {
    const response = await axios.get(`${BASE_URL}/restaurants${queryParams}`, {
      params: {
        page: page,
        per_page: perPage,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRestaurant = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/restaurants`, {
      params: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNeighborhood = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/neighborhoods`, {
      params: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchWaterProvider = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/providers`, {
      params: {
        pwsId: id,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRelatedInstances = async (ids, type) => {
  try {
    const res = [];
    // truncate to first 5 related, otherwise page will get overflowed
    const truncatedIds = ids.slice(0, Math.min(ids.length, 5));
    for (let i = 0; i < truncatedIds.length; i++) {
      if (type == "water-providers") {
        res.push(await fetchWaterProvider(truncatedIds[i]));
      } else if (type == "neighborhoods") {
        res.push(await fetchNeighborhood(truncatedIds[i]));
      } else if (type == "restaurants") {
        res.push(await fetchRestaurant(truncatedIds[i]));
      } else {
        throw new Error("Invalid type passed to fetchRelatedInstances");
      }
    }

    return res;
  } catch (error) {
    throw error;
  }
};

const searchProviders = async (query) => {
  try {
    const response = await axios.get(`${BASE_URL}/providers`, {
      params: {
        search: query,
      },
    });
    return response.data.response;
  } catch (error) {
    throw error;
  }
};

const searchNeighborhoods = async (query) => {
  try {
    const response = await axios.get(`${BASE_URL}/neighborhoods`, {
      params: {
        search: query,
      },
    });
    return response.data.response;
  } catch (error) {
    throw error;
  }
};

const searchRestaurants = async (query) => {
  try {
    const response = await axios.get(`${BASE_URL}/restaurants`, {
      params: {
        search: query,
      },
    });
    return response.data.response;
  } catch (error) {
    throw error;
  }
};

export const fetchSearchResults = async (query, type) => {
  try {
    if (type === "water-providers") {
      const response = await searchProviders(query);
      return response;
    } else if (type === "neighborhoods") {
      const response = await searchNeighborhoods(query);
      return response;
    } else if (type === "restaurants") {
      const response = await searchRestaurants(query);
      return response;
    } else if (type === "all") {
      const response = [];
      response.push(await searchProviders(query));
      response.push(await searchNeighborhoods(query));
      response.push(await searchRestaurants(query));
      return response;
    } else {
      throw new Error("Invalid type passed into fetchSearchResults");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchGoogleImage = async (query) => {
  try {
    const response = await axios.get(
      "https://www.googleapis.com/customsearch/v1",
      {
        params: {
          cx: "20e1ef67026214b80",
          q: query,
          searchType: "image",
          key: GOOGLE_SEARCH_KEY,
        },
      },
    );
    if (
      response &&
      response.data &&
      response.data.items &&
      response.data.items.length != 0
    ) {
      return response.data.items[0].link;
    }
    return "";
  } catch (error) {
    throw error;
  }
};

export const fetchFilters = async (type) => {
  try {
    if (type == "water-providers") {
      const response = await axios.get(`${BASE_URL}/providers/filters`);
      return response.data;
    } else if (type == "neighborhoods") {
      const response = await axios.get(`${BASE_URL}/neighborhoods/filters`);
      return response.data;
    } else if (type == "restaurants") {
      const response = await axios.get(`${BASE_URL}/restaurants/filters`);
      return response.data;
    } else {
      throw new Error("Invalid type passed to fetchFilters");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchVisData = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/visualization-data`);
        return response.data;
      } catch (error) {
        throw error;
      }
}

export const fetchProviderVisData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/provider-visualization-data`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
