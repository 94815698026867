import React from "react";

function Row(props) {
  const elements = props.elements;
  const numCols = props.numCols;

  return (
    <div className="row justify-content-center">
      {elements.map((element, index) => (
        <div key={index} className="col-lg-auto" id="grid-cell">
          {element}
        </div>
      ))}
    </div>
  );
}

export default function Grid(props) {
  /* Should generate the responsive Bootstrap grid given elements */
  const numCols = props.numCols;

  const gridElements = props.gridElements;

  let idx = 0;
  let cards = [];
  while (idx < gridElements.length) {
    cards.push(
      <Row
        elements={gridElements.slice(
          idx,
          Math.min(idx + numCols, gridElements.length),
        )}
        numCols={numCols}
        key={idx}
      />,
    );
    idx += numCols;
  }

  return (
    <div className="container-fluid" data-testid="grid">
      {cards}
    </div>
  );
}
