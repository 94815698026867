import { useNavigate } from "react-router-dom";

function LandingCard(props) {
  const navigate = useNavigate();

  function handleClick() {
    navigate(props.link);
  }

  return (
    <div className="card" id="landing-card">
      <img
        className="card-img-top"
        id="landing-card-image"
        src={props.image}
        alt="Card Image"
      />
      <div className="card-body">
        <h1>{props.cardTitle}</h1>
        <p>{props.cardText}</p>
      </div>
      <div className="landing-card-button-container">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClick}
        >
          {props.buttonText}
        </button>
      </div>
    </div>
  );
}

export default LandingCard;
