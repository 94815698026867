import { useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const [input, setInput] = useState("");

  const navigate = useNavigate();

  const handleSubmit = () => {
    const link = "/search?q=" + input;
    navigate(link);
    window.scrollTo(0, 0);
  };

  return (
    <Navbar bg="dark" data-bs-theme="dark" expand="lg">
      <Navbar.Brand href="/" id="navbar-brand">
        FAA
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/" id="nav-link">
            Home
          </Nav.Link>
          <Nav.Link href="/about" id="nav-link">
            About
          </Nav.Link>
          <Nav.Link href="/water-providers" id="nav-link">
            Water Providers
          </Nav.Link>
          <Nav.Link href="/neighborhoods" id="nav-link">
            Neighborhoods
          </Nav.Link>
          <Nav.Link href="/restaurants" id="nav-link">
            Restaurants
          </Nav.Link>
          <Nav.Link href="visualizations" id="nav-link">
            Visualizations
          </Nav.Link>
          <Nav.Link href="/provider-visualizations" id="nav-link">
            Provider Visualizations
          </Nav.Link>
        </Nav>
        <div id="nav-search-bar-container">
          <Form className="d-flex" id="nav-search-bar" onSubmit={handleSubmit}>
            <Form.Control
              type="text"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              onChange={(e) => setInput(e.target.value)}
            />
          </Form>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
