import { useNavigate } from "react-router-dom";
import { getName, filterImages } from "../../utils/utils";
import { fetchGoogleImage } from "../../utils/api";
import { useEffect, useState } from "react";

export default function NeighborhoodInstanceCard(props) {
  const instanceData = props.instanceData;

  const properties = [];
  // Push props manually
  properties.push("Population: " + instanceData.population);
  properties.push(
    "Average Household Income: $" + instanceData.avg_Household_Income,
  );
  properties.push("Median Rent: $" + instanceData.housing_Median_Rent);
  properties.push("Total Housing Units: " + instanceData.housing_Units);
  properties.push(
    "Available Housing Units: " + instanceData.housing_Units_Vacant,
  );

  const navigate = useNavigate();

  function handleClick() {
    const link = "/neighborhoods/" + instanceData.id;
    navigate(link); // Navigate to the "/treats" page
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

  return (
    <div className="card" id="instance-card" onClick={handleClick}>
      <img
        className="card-img-top"
        id="instance-card-image"
        src={filterImages(instanceData.image_link, "neighborhoods")}
        alt="Card Image"
      />
      <div className="card-body">
        <h1>{getName(instanceData.name)}</h1>
        {properties.map((prop, i) => (
          <p key={i}>{prop}</p>
        ))}
      </div>
      <div className="instance-card-button-container">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClick}
        >
          See more
        </button>
      </div>
    </div>
  );
}
