import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import { Form } from "react-bootstrap";
import { fetchSearchResults } from "../utils/api";
import Grid from "../components/Grid";
import ProviderInstanceCard from "../components/WaterProviders/ProviderInstanceCard";
import NeighborhoodInstanceCard from "../components/Neighborhoods/NeighborhoodInstanceCard";
import RestaurantInstanceCard from "../components/Restaurants/RestaurantInstanceCard";

function SearchPage() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");

  const [results, setResults] = useState(null);
  const [input, setInput] = useState(query ? query : "");
  const [title, setTitle] = useState("Search our website");
  const [image, setImage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    setInput(e.target.text);
    e.preventDefault();
  };

  useEffect(() => {
    let ignore = false;
    if (input) {
      setTitle(`Search results for "${input}"`);
      fetchSearchResults(input, "all").then((response) => {
        if (!ignore) {
          setResults(response);
        }
      });
    } else {
      setTitle("Search our website");
      setResults([]);
    }
    return () => {
      ignore = true;
    };
  }, [input]);

  if (!results || (results && !results[0])) {
    return (
      <>
        <NavBar />
        <div className="model-search-area">
          <h1>{title}</h1>
          <Form className="search-bar-container">
            <Form.Control
              type="text"
              defaultValue={query ? query : ""}
              placeholder="Search"
              className="lg-2"
              aria-label="Search"
              onSubmit={handleSubmit}
            />
          </Form>
        </div>
      </>
    );
  } else {
    return (
      <>
        <NavBar />
        <div className="model-search-area">
          <h1>{title}</h1>
          <Form className="search-bar-container">
            <Form.Control
              type="text"
              defaultValue={query ? query : ""}
              placeholder="Search"
              className="lg-2"
              aria-label="Search"
              onSubmit={handleSubmit}
            />
          </Form>
          <br></br>
          {results[0].length == 0 &&
            results[1].length == 0 &&
            results[2].length == 0 && <h4>No results found for "{input}"</h4>}
        </div>

        {results[0].length > 0 && (
          <>
            <h2>Relevant Water Providers:</h2>
            <Grid
              gridElements={results[0].map((provider, i) => (
                <ProviderInstanceCard key={i} instanceData={provider} />
              ))}
              numCols={Math.min(results[0].length, 5)}
            />
          </>
        )}
        {results[1].length > 0 && (
          <>
            <h2>Relevant Neighborhoods:</h2>
            <Grid
              gridElements={results[1].map((neighborhood, i) => (
                <NeighborhoodInstanceCard key={i} instanceData={neighborhood} />
              ))}
              numCols={Math.min(results[1].length, 5)}
            />
          </>
        )}
        {results[2].length > 0 && (
          <>
            <h2>Relevant Restaurants:</h2>
            <Grid
              gridElements={results[2].map((restaurant, i) => (
                <RestaurantInstanceCard key={i} instanceData={restaurant} />
              ))}
              numCols={Math.min(results[2].length, 5)}
            />
          </>
        )}
      </>
    );
  }
}

export default SearchPage;
