import NavBar from "../../components/Navbar";
import Grid from "../../components/Grid";
import { useParams } from "react-router-dom";
import { ToTitleCase, formatCityName, filterImages } from "../../utils/utils";
import {
  fetchRelatedInstances,
  fetchWaterProvider,
  GOOGLE_API_KEY,
} from "../../utils/api";
import { useState, useEffect } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import NeighborhoodInstanceCard from "../../components/Neighborhoods/NeighborhoodInstanceCard";
import RestaurantInstanceCard from "../../components/Restaurants/RestaurantInstanceCard";

function WaterProviderInstance() {
  const defaultCenter = { lat: 43.012361053526135, lng: -83.68679531519122 };

  const { id } = useParams();
  const [instance, setInstance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedNeighborhoods, setRelatedNeighborhoods] = useState(null);
  const [relatedRestaurants, setRelatedRestaurants] = useState(null);

  // get water provider data
  useEffect(() => {
    setLoading(true);
    const fetchProvider = async () => {
      const data = await fetchWaterProvider(id);
      setInstance(data);
    };

    fetchProvider();
    setLoading(false);
  }, []);

  // get related cards data
  useEffect(() => {
    if (instance == null) {
      return;
    }
    setLoading(true);
    const fetchRelatedNeighborhoods = async () => {
      if (instance.related_neighborhood_ids.length != 0) {
        const ids = instance.related_neighborhood_ids.split(",");
        setRelatedNeighborhoods(
          await fetchRelatedInstances(ids, "neighborhoods"),
        );
      }
    };

    const fetchRelatedRestaurants = async () => {
      if (instance.related_restaurant_ids.length != 0) {
        const ids = instance.related_restaurant_ids.split(",");
        setRelatedRestaurants(await fetchRelatedInstances(ids, "restaurants"));
      }
    };

    fetchRelatedNeighborhoods();
    fetchRelatedRestaurants();
    setLoading(false);
  }, [instance]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  if (loading) {
    return <h1>Loading...</h1>;
  } else {
    return (
      <>
        <NavBar />
        <div className="instance-container" id="water-provider">
          <img
            src={instance ? filterImages(instance.image_link, "provider") : ""}
            alt="Instance image"
            className="instance-image"
          />
          <h1>{formatCityName(ToTitleCase(instance?.name)) || "Loading.."}</h1>
          <p>
            Population Served:{" "}
            {instance?.population_served_count || "Loading..."}
          </p>
          <p>Address: {instance?.address || "See Map"}</p>
          <p>Phone Number: {instance?.phone_number || "Unavailable"}</p>
          <p>Email: {instance?.email || "Unavailable"}</p>
          <p></p>

          <div className="map-div">
            {!isLoaded ? (
              <h2>Loading...</h2>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container"
                center={
                  instance
                    ? { lat: instance.latitude, lng: instance.longitude }
                    : defaultCenter
                }
                zoom={14}
              >
                <MarkerF
                  position={
                    instance
                      ? { lat: instance.latitude, lng: instance.longitude }
                      : defaultCenter
                  }
                ></MarkerF>
              </GoogleMap>
            )}
          </div>
          <br></br>
          <br></br>
        </div>
        <br></br>
        <br></br>
        <h1 style={{ textAlign: "center" }}>Related Neighborhoods</h1>
        {relatedNeighborhoods && (
          <Grid
            gridElements={relatedNeighborhoods.map((neighborhood, i) => (
              <NeighborhoodInstanceCard key={i} instanceData={neighborhood} />
            ))}
            numCols={Math.min(relatedNeighborhoods.length, 5)}
          />
        )}
        <br></br>
        <br></br>
        <h1 style={{ textAlign: "center" }}>Related Restaurants</h1>
        {relatedRestaurants && (
          <Grid
            gridElements={relatedRestaurants.map((restaurant, i) => (
              <RestaurantInstanceCard key={i} instanceData={restaurant} />
            ))}
            numCols={Math.min(relatedRestaurants.length, 5)}
          />
        )}
      </>
    );
  }
}

export default WaterProviderInstance;
