import NavBar from "../components/Navbar";
import ScholarshipsByAidChart from "../components/ProviderCharts/ScholarshipsByAidChart";
import CityScholarshipsChart from "../components/ProviderCharts/CityScholarshipsChart";
import CollegeTuitionChart from "../components/ProviderCharts/CollegeTuitionChart";

function ProviderVisualizations() {
  return (
    <>
      <NavBar />
      <div id="provider-visualizations-container">
        <h1>Provider Visualizations</h1>
        <br></br>
        <br></br>
        <h2>Scholarships sorted by Aid Amount</h2>
        <ScholarshipsByAidChart />
        <br></br>
        <br></br>
        <hr id="provider-line-break" />
        <br></br>
        <br></br>
        <h2>Number of Scholarships per City</h2>
        <CityScholarshipsChart />
        <hr id="provider-line-break" />
        <br></br>
        <br></br>
        <h2>Colleges sorted by Tuition Amount</h2>
        <CollegeTuitionChart />
        <br></br>
        <br></br>
      </div>
    </>
  );
}

export default ProviderVisualizations;
