import NavBar from "../components/Navbar";
import LandingCard from "../components/LandingCard";

function Landing() {
  return (
    <>
      <NavBar />
      <div className="landing">
        <div className="landing-content">
          <h1>Flint Aqua Advocates</h1>
          <h3>
            Providing up-to-date information on water quality, housing, and restaurants in Flint, Michigan
          </h3>
        </div>
      </div>
      <div className="landing-card-container">
        <LandingCard
          image="/flint-water-tower-picture.png"
          cardTitle="Water Providers"
          cardText="Search water providers in Flint"
          buttonText="Water Providers"
          link="/water-providers"
        />
        <LandingCard
          image="/maplewood-heights-picture.webp"
          cardTitle="Neighborhoods"
          cardText="Learn more about your area"
          buttonText="Neighborhoods"
          link="/neighborhoods"
        />
        <LandingCard
          image="/landing-restaurant-pic.jpeg"
          cardTitle="Restaurants"
          cardText="Learn more about restaurants"
          buttonText="Restaurants"
          link="/restaurants"
        />
      </div>
    </>
  );
}

export default Landing;
